import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./components/dashboard";
import NotFound from "./components/notFound";
import NavBar from "./components/common/navBar";
import "./App.css";
import ErrorComponent from "./components/error";
import CircularProgress from "@material-ui/core/CircularProgress";
import MessageComponent from "./components/message";
import PurchaseCancelled from "./components/purchaseCancelled";
import PurchaseConfirmed from "./components/purchaseConfirmed";
import AutoRenewCancelled from "./components/autoRenewCancelled";
import AutoRenewNotCancelled from "./components/autoRenewNotCancelled";

class App extends Component {

  state = {
    renderNavBar: false,
    navBarHeading: "",
    renderHomeIcon: false,
    loading: false,    
  };

  constructor(props, state) {
    console.log("App constructor");
    super(props, state);
  }

  // Handle page change
  configureNavBar = (renderNavBar, navBarHeading, renderHomeIcon) => {
    this.setState({
      renderNavBar: renderNavBar,
      navBarHeading: navBarHeading,
      renderHomeIcon: renderHomeIcon
    });
  };

  render() {
    const { user, navBarHeading, renderHomeIcon } = this.state;
    return (
      <React.Fragment>
        {this.state.renderNavBar && (
          <NavBar
            user={user}
            heading={navBarHeading}
            renderHomeIcon={renderHomeIcon}
          />
        )}
        <main className="container">
          {this.state && this.state.loading && (
            <div style={{backgroundColor:"white", height:"800px", overflow:"hidden"}}>
              <CircularProgress
                style={{
                  marginLeft: "43%",
                  marginTop: "20em"
                }}
              />
            </div>            
          )}
          {this.state && !this.state.loading && (
            <Switch>
              <Route
                path="/dashboard"
                render={props => (
                  <Dashboard
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />
              <Route path="/not-found" component={NotFound} />
              <Route
                path="/error/:errorCode"
                render={(props) => (
                  <ErrorComponent
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />
              <Route
                path="/error"
                render={props => (
                  <ErrorComponent
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />              
              <Route
                path="/purchaseCancelled"
                render={props => (
                  <PurchaseCancelled
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />
              <Route
                path="/purchaseConfirmed"
                render={props => (
                  <PurchaseConfirmed
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />
              <Route
                path="/autoRenewCancelled"
                render={props => (
                  <AutoRenewCancelled
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />
              <Route
                path="/autoRenewNotCancelled"
                render={props => (
                  <AutoRenewNotCancelled
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />
              <Route
                path="/message/:message"
                render={props => (
                  <MessageComponent
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                    message={"test message"}
                  />
                )}
              />
              <Route
                path="/*"
                render={props => (
                  <Dashboard
                    {...props}
                    user={user}
                    configureNavBar={this.configureNavBar}
                  />
                )}
              />
              <Redirect from="/" exact to="dashboard" />
              <Redirect to="/not-found" />
            </Switch>
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default App;
