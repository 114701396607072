import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit
  },
  typography: {
    textAlign: "left"
  }
});

function PaperSheet(props) {
  const { classes, heading, messages, styles } = props;
  return (
    <div>
      <Paper className={classes.root} elevation={1}>
        {heading && (
          <Typography style={{ ...styles }} variant="h6" component="h6">
            {heading}
          </Typography>
        )}
        {messages && (
          <Typography
            component="div"
            className={classes.typography}
            style={{ ...styles }}
            variant="body1"
          >
            {messages.map(message => (
              <div key={message.key}>
                <div>
                  {message.content}
                  <br />
                </div>
              </div>
            ))}
          </Typography>
        )}
      </Paper>
    </div>
  );
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaperSheet);
