import React from "react";
import {injectIntl, intlShape} from "react-intl";

const NotFound = () => {
  return <h1>Not Found</h1>;
};

NotFound.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(NotFound);

