import http from "./httpService";
import { apiUrl } from "../config.json";

// need to handle more than 1 sessionInfo
export function getSessionInfo() {
  console.log("GETTING SESSION INFO");
  return new Promise((resolve, reject) => {
    http
      .get(
        `${apiUrl}/subscriber/session/lookup?operation=subscriberSessionLookup`
      )
      .then(response => {
        console.log(response);
        if (response.data.length) {
          // We only use the first sessionInfo
          let sessionInfo = response.data.sessionInfo[0];
          resolve(sessionInfo);
        }
      })
      .catch(reject);
  });
}

export function getSubscriberByMsisdn(subscriberMsisdn) {
  return new Promise((resolve, reject) => {

    http.get(`${apiUrl}/spcm/subscribers?operation=getSubscriberByMsisdn&subscriberMsisdn=${subscriberMsisdn}`)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
  });
}

