import http from "./httpService";
import { apiUrl } from "../config.json";

export function getAutoRenew(){
    return new Promise((resolve, reject) => {
      http.get(`${apiUrl}/lists/subscriberAutoRenew?operation=getSubscriberAutoRenew`)
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(reject);
    });
  }

  export function addToAutoRenewList(){
    return new Promise((resolve, reject) => {
      http.post(`${apiUrl}/lists/subscriberAutoRenew?operation=addSubscriberAutoRenew`)
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(reject);
    });
  }

  export function removeFromAutoRenewList(){
    return new Promise((resolve, reject) => {
      http.delete(`${apiUrl}/lists/subscriberAutoRenew?operation=removeSubscriberAutoRenew`)
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(reject);
    });
  }