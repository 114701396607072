import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl, intlShape } from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  list: {
    paddingInlineStart: "30px",
  },
});

class ErrorComponent extends Component {
  errorMessages;

  constructor({ configureNavBar }) {
    super();
    configureNavBar(true, "defaultTitle", true);
  }

  componentWillMount() {
    let errorCode = this.props.match.params["errorCode"];
    let error = null;
    console.log(errorCode);
    console.log(this.props);
    if (this.props.location.state && this.props.location.state.error) {
      error = this.props.location.state.error;
    } else if (errorCode) {
      // use error code from parameter
      error = { code: errorCode };
    }
    // if error doesn't have error message then use error code lookup message
    if (error) {
      if (!error.message) {
        error.message = this.props.intl.formatMessage({
          id: error.code,
        });
      }
      // If no message found for the error code use the default
      if (error.message.startsWith("errorMessages.error")) {
        error.message = this.props.intl.formatMessage({
          id: "defaultError2",
        });
      }
    } else {
      error = {
        message: this.props.intl.formatMessage({
          id: "defaultError2",
        }),
      };
    }
    this.setState({
      error: error,
    });
  }

  goBack = () => {
    this.props.history.push("/");
  };

  render() {
    const { error } = this.state;
    const { intl } = this.props;
    return (
      <Card
        style={{
          boxShadow: "none",
          padding: "2em",
        }}
      >
        <CardContent style={{
          textAlign: "center"}}>          
          <Typography gutterBottom variant="h6" color="primary">
            {intl.formatMessage({
              id: "defaultError1",
            })}
          </Typography>  
          <Typography gutterBottom variant="h6" color="primary" style={{marginTop:"2em"}}>
            {error.message}
          </Typography>    
          {error.code == 401 && (
            <Button 
              color="primary"
              autoFocus
              variant="contained"
              style={{margin:"2em", fontWeight:"bold"}}
              component={Link} to={'/'}>
              {intl.formatMessage({
                id: "defaultError2",
              })}
            </Button>)}         
        </CardContent>
      </Card>
    );
  }
}

ErrorComponent.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(withStyles(styles)(ErrorComponent));
