function init() {
  //Raven.config("https://c69d4833b9ec449f9a0e42e1b6e43f5e@sentry.io/1392445", {
  //  release: "0-0-1",
  //  environment: "development-test"
  //}).install();
}

function log(error) {
  console.error(error);
  //Raven.captureException("Logging the error", error);
}

export default {
  init,
  log
};
