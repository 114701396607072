import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl, intlShape } from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Footer from "./common/footer";

const styles = theme => ({
  progress: {
    marginLeft: "43%",
    marginTop: "45%"
  },
  buttonContainer: {
    display: "block",
    textAlign: "center",
    marginBottom: "1em",
    marginTop: "1em"
  },
});

class PurchaseCancelled extends Component {
  state = {};

  constructor({ configureNavBar }) {
    super();
    configureNavBar(true, "", false);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  returnButtonClicked(e){
    this.props.history.push("/")
  }

  render() {
    const { intl } = this.props;
    const { classes } = this.props;
    const throttleRemoved = this.props.location.state.throttleRemoved;
    const autoRenew = this.props.location.state.autoRenew;
      return (
        <div style={{ maxWidth: "500px", margin: "auto", height:"100%"}}>          
          <Card
            style={{
              boxShadow: "none",
              padding: "1em",
            }}
          >
            <CardContent style={{
              textAlign: "center", marginBottom:"6em"}}>
              {autoRenew ? 
                (
                  <Typography gutterBottom variant="h6" color="primary">
                    {intl.formatMessage({
                      id: "purchaseCancelled.notRenewedAutoRenew",
                    })}
                  </Typography>
                ) : 
                (
                  <Typography gutterBottom variant="h6" color="primary">
                    {intl.formatMessage({
                      id: "purchaseCancelled.notRenewed",
                    })}
                  </Typography>
                )
              }
              {!throttleRemoved ? 
                (<Typography gutterBottom variant="h6" color="primary" style={{marginTop:"2em"}}>
                {intl.formatMessage({
                  id: "purchaseCancelled.reducedSpeed",
                })}
              </Typography>) : null
              }
              
            </CardContent>
          </Card>
          {!throttleRemoved && (<Footer />)}
        </div>
      );
  }
}

PurchaseCancelled.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles)(PurchaseCancelled));