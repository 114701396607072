import axios from "axios";
import logger from "./logService";
import { i18nUrl } from "../config.json";

const axiosInstance = axios.create({
  headers: {
    Authorization: localStorage.getItem("access_token"),
  },
});

axiosInstance.defaults.timeout = 15000;

axiosInstance.interceptors.request.use((request) => {
  /* var params = new URLSearchParams();
  params.append("tenant", "brav3");
  request.params = params; */

  request.url = encodeURI(request.url);
  if (request.url.indexOf(i18nUrl) > -1) {
    request.headers["Authorization"] = null;
  } else {
    request.headers["Authorization"] = localStorage.getItem("access_token");
  }
  console.log(request);
  return request;
});

axiosInstance.interceptors.response.use(null, (error) => {
  // Unexpected (network down, server down, db down, bug) -
  // - Log them
  // - Display a generic and friendly error message
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    if (error.code === "ECONNABORTED") {
      error.code = "408";
    } else {
      error.code = error.response.status;
    }
    logger.log(error);
  }
  return Promise.reject(error);
});

export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
};
