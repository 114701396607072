import axios from "axios";

class LoginServiceClass {
  tokenSetting;

  obtainNewTokenAndRetry = (axiosInstance, lastRequest) => {
    console.log("OBTAIN NEW TOKEN AND RETRY");
    if (localStorage.getItem("lookup_token")) {
      return this.login(localStorage.getItem("lookup_token"))
        .then(response => {
          console.log(response);
          if (
            response.status &&
            response.status >= 400 &&
            response.status < 500
          ) {
            return Promise.reject(response);
          }
          return axiosInstance.request(lastRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    } else {
      return this.login(null)
        .then(response => {
          if (
            response.status &&
            response.status >= 400 &&
            response.status < 500
          ) {
            return Promise.reject(response);
          }
          return axiosInstance.request(lastRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  };

  login = token => {
    return axios
      .post("/cpserver/login", null, {})
      .then(response => {
        localStorage.setItem("access_token", response.headers["authorization"]);
        return response;
      })
      .catch(err => {
        return err.response;
      });
  };
}

const LoginService = new LoginServiceClass();
export default LoginService;
