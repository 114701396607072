import HttpService from "./httpService";
import { BehaviorSubject } from "rxjs";

class PropertiesServiceClass {
  $properties = new BehaviorSubject();

  init() {
    console.log("Properties service init");
    return new Promise((resolve, reject) => {
      this.updateConfigProperties()
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }

  updateConfigProperties() {
    console.log("Properties service called");
    return new Promise((resolve, reject) => {
      HttpService.get(`/cpserver/properties`)
        .then(response => {
          this.$properties.next(response.data);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

const PropertiesService = new PropertiesServiceClass();
export default PropertiesService;
