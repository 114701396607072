import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl, intlShape } from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Footer from "./common/footer";

const styles = (theme) => ({
  progress: {
    marginLeft: "43%",
    marginTop: "45%",
  },
  buttonContainer: {
    display: "block",
    textAlign: "center",
    marginBottom: "1em",
    marginTop: "1em",
  },
});

class PurchaseConfirmed extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constructor({ configureNavBar }) {
    super();
    configureNavBar(true, "", false);
  }

  render() {
    const { intl } = this.props;
    const automaticRenewal = this.props.location.state.automaticRenewal;
    const renewalSuccessful = this.props.location.state.renewalSuccessful;
    const price = this.props.location.state.price;
    return (
      <div style={{ maxWidth: "500px", margin: "auto", height: "100%" }}>
        <Card
          style={{
            boxShadow: "none",
            padding: "1em",
          }}
        >
          <CardContent style={{ textAlign: "center", marginBottom: "6em" }}>
            <Typography gutterBottom variant="h6" color="primary">
              {intl.formatMessage({
                id: "purchaseConfirmed.submitted",
              })}
            </Typography>
            {automaticRenewal == true ? (
              <div>
                {renewalSuccessful ? (
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="primary"
                    style={{ marginTop: "2em" }}
                  >
                    {intl.formatMessage({
                      id: "purchaseConfirmed.autoRenew1",
                    })}
                    {price + " "}
                    {intl.formatMessage({ id: "purchaseConfirmed.autoRenew2" })}
                  </Typography>
                ) : (
                  <div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{ color: "orange" }}
                    >
                      {intl.formatMessage({
                        id: "purchaseConfirmed.autoRenewFailed",
                      })}
                    </Typography>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Typography
                  gutterBottom
                  variant="h6"
                  color="primary"
                  style={{ marginTop: "2em" }}
                >
                  {intl.formatMessage({
                    id: "purchaseConfirmed.oneTimePurchase",
                  })}
                  {price}
                  {"."}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
        <Footer />
      </div>
    );
  }
}

PurchaseConfirmed.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(withStyles(styles)(PurchaseConfirmed));
