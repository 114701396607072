import {i18nUrl} from "../config.json";
import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import ptLocaleData from 'react-intl/locale-data/pt';
import {addLocaleData} from 'react-intl';
import {flatten} from 'flat';
import http from "./httpService";

export function initTranslations() {
  return new Promise((resolve, reject) => {
    addLocaleData({ ...enLocaleData, ...esLocaleData, ...ptLocaleData });
    const locale = navigator.language && navigator.language.split("-")[0];
    http
      .get(`${i18nUrl}/${locale}`)
      .then((response) => {
        if (response.data) {
          resolve({ locale: locale || "en", messages: flatten(response.data) });
        }
        else{
          var errorFile = createDefaultErrorFile(locale);
          resolve({ locale: locale || "en", messages: flatten(errorFile) });
        }
      })
      .catch(error => {
        var errorFile = createDefaultErrorFile(locale);
        resolve({ locale: locale || "en", messages: flatten(errorFile) });      
      });      
  });
}

function createDefaultErrorFile(locale){
  var errorFile;
  if(locale == "pt"){
    errorFile = {
      "defaultError1": "Sua solicitação não pode ser completada.",
      "defaultError2":"Favor tentar novamente."
    
    };
  }
  else{
    errorFile = {
      "defaultError1": "Your request was not completed.",
      "defaultError2":"Please try again."
    };
  }
  return errorFile;
}
