import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl, intlShape } from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";

const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    align: "center",
    paddingBottom: theme.spacing.unit * 2,
    width: "100%"
  }
});

const Footer = props => {
  const { intl } = props;
  const {dialogFooter} = props;
  const {showLink} = props;
  return (
    <div style={{height:"100%"}}>
      <Card
        style={{
          backgroundColor: "#e7e7e7",
          boxShadow: "none",
          height:"100%"
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="subtitle1" style={{color:"#666"}}>
            {intl.formatMessage({
              id: "footer.localTime",
            })}
          </Typography>
          {dialogFooter ?
            (
              <Typography gutterBottom variant="subtitle1" style={{display:"inline", color:"#666"}}>
                 {intl.formatMessage({
                    id: "footer.terms",
                  })}
              </Typography>
            ) : 
            (
              showLink && (
              <div>
                <Typography gutterBottom variant="subtitle1" style={{display:"inline", color:"#666"}}>
                  {intl.formatMessage({
                    id: "footer.prompt",
                  })}{" "}
                </Typography>
                <Link
                    href={intl.formatMessage({
                      id: "footer.link",
                    })}
                    style={{ color: "#660099", display: "inline-block" }}
                    underline={"always"}
                  >
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      style={{ color: "#660099" }}
                    >
                      {intl.formatMessage({
                        id: "footer.linkText",
                      })}
                    </Typography>
                  </Link>
              </div>
              )
            )}
        </CardContent>
      </Card>
    </div>
  );
};

Footer.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles)(Footer));
