import http from "./httpService";
import { apiUrl } from "../config.json";

export function purchasePlan(planDefinition) {
  return new Promise((resolve, reject) => {
    http.post(
      apiUrl +
        "/subscriber/plans?operation=subscriberPurchasePlan&planDefinitionName=" +
        planDefinition.name
    ).then(response => {
      console.log(response);
      resolve(response);
    })
    .catch(reject);
  })
}

export async function getActivePlans() {
  const { data: planList } = await http.get(
    apiUrl + "/subscriber/plans?operation=subscriberGetActivePlans"
  );
  return planList.plan;
}

export function updatePlan(planId, state) {
  return http.put(
    apiUrl +
      "/subscriber/plans/plan?operation=subscriberUpdatePlan&planId=" +
      planId +
      "&state=" +
      state
  );
}

export function somOfferLookup(sessionInfo){
  console.log("Getting SOM offer");
  var ruleListNames = sessionInfo.installedRuleList.installedRule.map( function( el ){ 
    return el.ruleName;
  });
  ruleListNames = ruleListNames.join("|");
  ruleListNames = "|" + ruleListNames + "|";

  var locationRules = sessionInfo.locationProfileList.locationProfile.map( function( el ){ 
    return el.name;
  });
  locationRules = locationRules.join("|");
  locationRules = "|" + locationRules + "|";


  return new Promise((resolve, reject) => {
    http.post(`${apiUrl}/som/offerLookup?operation=somOfferLookup&installedRules=${ruleListNames}&locationRules=${locationRules}`)
        .then(response => {
          console.log(response);
          resolve(response);
        })
        .catch(reject);
  });
}

export function getPlanDefinition(planName){
  console.log("Getting plan definition");
  return new Promise((resolve, reject) => {
    http.get(`${apiUrl}/planDefinition/lookup?operation=planDefinitionLookup&planName=${planName}`)
        .then(response => {
          console.log(response);
          resolve(response);
        })
        .catch(reject);
  });
}
