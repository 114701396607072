import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {withStyles} from "@material-ui/core/styles";
import {injectIntl, intlShape} from "react-intl";
import watermark_logo from "../../images/watermark_logo.png"; 

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  homeButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

const logoStyle = {
  margin: ".5em auto .5em auto",
  height:"40px",
};

const NavBar = props => {
  const { classes } = props;
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <img src={watermark_logo} alt="logo" style={logoStyle}/>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </div>
  );
};

NavBar.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles)(NavBar));
