import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import PaperSheet from "./common/paperSheet";
import { injectIntl, intlShape } from "react-intl";

const styles = theme => ({
  root: {
    padding: theme.spacing.unit
  },
  list: {
    paddingInlineStart: "30px"
  }
});

class MessageComponent extends Component {
  constructor({ configureNavBar }) {
    super();
    configureNavBar(true, "defaultTitle", true);
  }

  componentWillMount() {
    let message = this.props.match.params["message"];
    let messageString = null;
    if (message) {
      messageString = message;
    }
    this.setState({
      message: messageString
    });
  }

  render() {
    return (
      <PaperSheet
        styles={{
          textAlign: "center",
          minHeight: "300px",
          paddingTop: "150px"
        }}
        messages={[
          {
            content: this.state.message,
            key: "message"
          }
        ]}
      />
    );
  }
}

MessageComponent.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles)(MessageComponent));
