import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import logger from "./services/logService";
import { initTranslations } from "./services/translationService";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";
import PropertiesService from "./services/propertiesService";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "ITC Officina Serif Std Book",
    h1: {
      fontFamily: "ITC Officina Serif Std Extra Bold",
    },
    h2: {
      fontFamily: "ITC Officina Serif Std Bold",
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "ITC Officina Serif Std Book",
      color: "#660099",
      fontWeight: "bold",
      lineHeight: "1.2"
    },
    subtitle1: {
      color: "#660099",
      //fontWeight: "bold",
      lineHeight: "1.2"
    },
    body1: {
      color: "#660099",
    },
    h4: {
      fontFamily: "ITC Officina Serif Std Book",
      fontWeight: "bold",
      color: "#6d6d6d",
    },
    h5: {
      fontFamily: "ITC Officina Serif Std Book",
      color: "#6d6d6d",
    },
    subtitle2: {
      color: "#6d6d6d",
      fontSize: ".85em",
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      light: "#944db8",
      main: "#660099",
      dark: "#220033",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#009926",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});


logger.init();
initTranslations().then(({ locale, messages }) => {
  PropertiesService.init().then(() => {
    const el = document.getElementById("pure-material-progress-circular");
    if (el) {
      el.remove();
    }
    ReactDOM.render(
      <IntlProvider locale="en" messages={messages}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MuiThemeProvider>
      </IntlProvider>,
      document.getElementById("root")
    );
  });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
registerServiceWorker();

// reload once when the new Service Worker starts activating
var refreshing;
navigator.serviceWorker.addEventListener("controllerchange", function() {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});
