import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import LoginService from "../services/loginService";
import { injectIntl, intlShape } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Footer from "./common/footer";
import { getSessionInfo } from "../services/subscriberService";
import {
  somOfferLookup,
  getPlanDefinition,
  purchasePlan,
} from "../services/subscriberPlanService";
import {
  getAutoRenew,
  addToAutoRenewList,
  removeFromAutoRenewList,
} from "../services/listManagerService";
import NavBar from "./common/navBar";

const styles = (theme) => ({
  progress: {
    marginLeft: "43%",
    marginTop: "45%",
  },
  buttonContainer: {
    display: "block",
    textAlign: "center",
    marginBottom: "1em",
    marginTop: ".5em",
  },
});

class Dashboard extends Component {
  state = {
    isAuthenticated: false,
    automaticRenewal: false,
    confirmPurchaseDialogOpen: false,
    confirmCancelAutoRenewDialogOpen: false,
    throttleRemoved: false,
    throttled: false,
    sessionInfo: null,
    planDefinition: null,
    loading: true,
    addToAutoRenew: false,
  };

  constructor({ configureNavBar }) {
    super();
    configureNavBar(true, "", false);
  }

  async componentDidMount() {
    await this.checkTranslations()
      .then((translationsPresent) => {
        this.authenticate();
      })
      .catch((error) => {
        console.log("no translations");
        this.props.history.push("/error");
      });
  }

  //check if full translations file present
  checkTranslations() {
    return new Promise((resolve, reject) => {
      const { intl } = this.props;
      if (Object.keys(intl["messages"]).length <= 1) {
        reject();
      } else resolve();
    });
  }

  async authenticate() {
    console.log("Authenticating...");

    await LoginService.login()
      .then((response) => {
        if (response && response.status >= 400 && response.status < 600) {
          console.log("login error: " + response);
          if (response.status === 401) {
            this.props.history.push("/error/401");
          } else this.props.history.push("/error");
        } else {
          this.setState({
            isAuthenticated: true,
          });
          getSessionInfo()
            .then((response) => {
              this.setState({
                sessionInfo: response,
              });
              this.checkAutoRenew().then((response) => {
                this.checkIfThrottled();
              });
            })
            .catch((error) => {
              this.props.history.push("/error");
            });
        }
      })
      .catch((error) => {
        this.props.history.push("/error");
      });
  }

  async checkIfThrottled() {
    if (this.state && this.state.sessionInfo) {
      console.log(this.state.sessionInfo.installedRuleList);
      if (
        this.state.sessionInfo.installedRuleList.installedRule.filter((e) =>
          e.ruleName.includes("ALL_DYN_THROTTLE_REMOVED")
        ).length > 0 ||
        this.state.automaticRenewal
      ) {
        this.setState({
          throttleRemoved: true,
          throttled: false,
        });
        this.getPlanDefinition();
      } else if (
        !this.state.sessionInfo.installedRuleList.installedRule.filter((e) =>
          e.ruleName.includes("THROTTLE")
        ).length > 0 &&
        !this.state.sessionInfo.installedRuleList.installedRule.filter((e) =>
          e.ruleName.includes("THROTTLE_REMOVED")
        ).length > 0
      ) {
        this.setState({
          throttled: false,
        });
        this.getPlanDefinition();
      } else {
        this.setState({
          throttled: true,
        });
        this.getPlanDefinition();
      }
    }
  }

  async checkAutoRenew() {
    console.log("getting auto renew");
    await getAutoRenew()
      .then((response) => {
        if (response.status === 200) {
          console.log("Auto renew true");
          this.setState({
            automaticRenewal: true,
          });
        } else if (response.status === 204) {
          console.log("auto renew false");
          this.setState({
            automaticRenewal: false,
          });
        } else {
          this.props.history.push("/error");
        }
      })
      .catch((error) => {
        this.props.history.push("/error");
      });
  }

  async getPlanDefinition() {
    const { intl } = this.props;
    await somOfferLookup(this.state.sessionInfo)
      .then((response) => {
        if (response.status == 200) {
          getPlanDefinition(response.data.planName).then((response) => {
            console.log(response.data);
            this.setState({
              planDefinition: response.data,
              loading: false,
            });
          });
        } else if (response.status == 204) {
          var err = new Error();
          err.message = intl.formatMessage({ id: "errorNoOfferForLocation" });
          this.props.history.push({
            pathname: `/error`,
            state: { error: err },
          });
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: `/error`,
        });
      });
  }

  handlePurchase = () => {
    if (this.state.throttled) {
      purchasePlan(this.state.planDefinition)
        .then((response) => {
          if (response.status == 200) {
            if (this.state.addToAutoRenew == true) {
              addToAutoRenewList()
                .then((response) => {
                  if (response.status == 200) {
                    this.props.history.push({
                      pathname: "/purchaseConfirmed",
                      state: {
                        automaticRenewal: true,
                        renewalSuccessful: true,
                        price:
                          this.state.planDefinition &&
                          (this.state.planDefinition.summary / 100)
                            .toFixed(2)
                            .replace(".", ","),
                      },
                    });
                  } else {
                    this.props.history.push({
                      pathname: "/purchaseConfirmed",
                      state: {
                        automaticRenewal: true,
                        renewalSuccessful: false,
                        price:
                          this.state.planDefinition &&
                          (this.state.planDefinition.summary / 100)
                            .toFixed(2)
                            .replace(".", ","),
                      },
                    });
                  }
                })
                .catch((error) => {
                  this.props.history.push({
                    pathname: "/purchaseConfirmed",
                    state: {
                      automaticRenewal: false,
                      renewalSuccessful: false,
                      price:
                        this.state.planDefinition &&
                        (this.state.planDefinition.summary / 100)
                          .toFixed(2)
                          .replace(".", ","),
                    },
                  });
                });
            } else {
              this.props.history.push({
                pathname: "/purchaseConfirmed",
                state: {
                  automaticRenewal: false,
                  renewalSuccessful: true,
                  price:
                    this.state.planDefinition &&
                    (this.state.planDefinition.summary / 100)
                      .toFixed(2)
                      .replace(".", ","),
                },
              });
            }
          } else {
            console.log("Error purchasing, response not 200");
            this.props.history.push(`/error`);
          }
        })
        .catch((error) => {
          this.props.history.push("/error");
        });
    } else if (
      (this.state.throttleRemoved || !this.state.throttled) &&
      this.state.addToAutoRenew
    ) {
      purchasePlan(this.state.planDefinition)
        .then((response) => {
          console.log(response);
          if (response.status == 200 || response.data.statusCode == 412) {
            addToAutoRenewList()
              .then((response) => {
                if (response.status == 200) {
                  this.props.history.push({
                    pathname: "/purchaseConfirmed",
                    state: {
                      automaticRenewal: true,
                      renewalSuccessful: true,
                      price:
                        this.state.planDefinition &&
                        (this.state.planDefinition.summary / 100)
                          .toFixed(2)
                          .replace(".", ","),
                    },
                  });
                } else {
                  this.props.history.push({
                    pathname: "/purchaseConfirmed",
                    state: {
                      automaticRenewal: true,
                      renewalSuccessful: false,
                      price:
                        this.state.planDefinition &&
                        (this.state.planDefinition.summary / 100)
                          .toFixed(2)
                          .replace(".", ","),
                    },
                  });
                }
              })
              .catch((error) => {
                this.props.history.push({
                  pathname: "/purchaseConfirmed",
                  state: {
                    automaticRenewal: false,
                    renewalSuccessful: false,
                    price:
                      this.state.planDefinition &&
                      (this.state.planDefinition.summary / 100)
                        .toFixed(2)
                        .replace(".", ","),
                  },
                });
              });
          } else {
            console.log("Error purchasing, response not 200 or 412");
            this.props.history.push(`/error`);
          }
        })
        .catch((error) => {
          this.props.history.push("/error");
        });
    } else {
      this.props.history.push({
        pathname: "/error",
      });
    }
  };

  confirmPurchase = (e) => {
    console.log(e.currentTarget.id);
    if (e.currentTarget.id == "oneTimePurchase") {
      this.setState({
        addToAutoRenew: false,
        confirmPurchaseDialogOpen: true,
      });
    } else {
      this.setState({
        addToAutoRenew: true,
        confirmPurchaseDialogOpen: true,
      });
    }
  };

  confirmCancelAutoRenew = (e) => {
    this.setState({
      confirmCancelAutoRenewDialogOpen: true,
    });
  };

  cancelPurchase = () => {
    this.setState({ confirmPurchaseDialogOpen: false });
    let notThrottled = true;
    if (this.state.throttled) {
      notThrottled = false;
    }
    this.props.history.push("/purchaseCancelled");
    this.props.history.push({
      pathname: "/purchaseCancelled",
      state: {
        throttleRemoved: notThrottled,
        autoRenew: this.state.automaticRenewal,
      },
    });
  };

  autoRenewNotCancelled = () => {
    this.setState({ confirmCancelAutoRenewDialogOpen: false });
    this.props.history.push("/autoRenewNotCancelled");
    this.props.history.push({
      pathname: "/autoRenewNotCancelled",
      state: {
        price:
          this.state.planDefinition &&
          (this.state.planDefinition.summary / 100)
            .toFixed(2)
            .replace(".", ","),
      },
    });
  };

  cancelAutoRenew = (e) => {
    removeFromAutoRenewList()
      .then((response) => {
        if (response.status !== 200) {
          this.props.history.push("/error");
        } else {
          this.props.history.push({
            pathname: "/autoRenewCancelled",
            state: {
              price:
                this.state.planDefinition &&
                (this.state.planDefinition.summary / 100)
                  .toFixed(2)
                  .replace(".", ","),
            },
          });
        }
      })
      .catch((error) => {
        this.props.history.push("/error");
      });
  };

  render() {
    const { classes } = this.props;
    const { intl } = this.props;
    if (
      !intl.formatMessage({
        id: "defaultTitle",
      })
    ) {
      this.props.history.push("/error");
    }
    console.log("Throttled? " + this.state.throttled);
    console.log("Throttle Removed? " + this.state.throttleRemoved);
    //loading set to false when get plan definition completes to ensure all data is loaded simultaneously
    if (this.state && !this.state.loading) {
      return (
        <div style={{ maxWidth: "500px", margin: "auto", height: "100%" }}>
          <Dialog
            open={this.state && this.state.confirmPurchaseDialogOpen}
            onClose={() => this.setState({ confirmPurchaseDialogOpen: false })}
            aria-labelledby="responsive-dialog-title"
            fullScreen
            style={{
              maxWidth: "500px",
              margin: "auto",
              backgroundColor: "#e7e7e7",
            }}
          >
            <NavBar></NavBar>
            <DialogContent
              style={{
                textAlign: "center",
                padding: "2.5em 2.5em .5em 2.5em",
                height: "250px",
                flex: "none",
              }}
            >
              {!this.state.addToAutoRenew ? (
                <DialogContentText
                  style={{
                    color: "#660099",
                    fontWeight: "bold",
                    marginBottom: "2em",
                  }}
                  variant="subtitle1"
                >
                  {intl.formatMessage({
                    id: "dashboard.oneTimeConfirmPrompt",
                  })}
                  {this.state.planDefinition &&
                    (this.state.planDefinition.summary / 100)
                      .toFixed(2)
                      .replace(".", ",")}
                  {"?"}
                </DialogContentText>
              ) : (
                <DialogContentText
                  style={{ color: "#660099", marginBottom: "2em" }}
                  variant="subtitle1"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "dashboard.repeatConfirmPrompt1",
                      }),
                    }}
                  ></span>
                  <b>
                    {this.state.planDefinition &&
                      (this.state.planDefinition.summary / 100)
                        .toFixed(2)
                        .replace(".", ",")}
                  </b>{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "dashboard.repeatConfirmPrompt2",
                      }),
                    }}
                  ></span>
                </DialogContentText>
              )}
              <Button
                autoFocus
                onClick={this.cancelPurchase.bind(this)}
                id="purchaseButtonCancel"
                variant="contained"
                style={{ margin: "1em", color: "#660099", fontWeight: "bold" }}
              >
                {intl.formatMessage({
                  id: "dashboard.cancel",
                })}
              </Button>
              <Button
                onClick={() => this.handlePurchase()}
                color="primary"
                autoFocus
                id="purchaseButtonAccept"
                variant="contained"
                style={{ margin: "1em", fontWeight: "bold" }}
              >
                {intl.formatMessage({
                  id: "dashboard.confirm",
                })}
              </Button>
            </DialogContent>
            <Footer dialogFooter={true} />
          </Dialog>

          <Dialog
            open={this.state && this.state.confirmCancelAutoRenewDialogOpen}
            onClose={() =>
              this.setState({ confirmCancelAutoRenewDialogOpen: false })
            }
            aria-labelledby="responsive-dialog-title"
            fullScreen
            style={{ maxWidth: "500px", margin: "auto" }}
          >
            <NavBar></NavBar>
            <DialogContent
              style={{
                textAlign: "center",
                padding: "2.5em 2.5em 1em 2.5em",
                height: "250px",
                flex: "none",
              }}
            >
              <DialogContentText
                style={{
                  color: "#660099",
                  fontWeight: "bold",
                  marginBottom: "2em",
                }}
                variant="subtitle1"
              >
                {intl.formatMessage({
                  id: "dashboard.confirmCancelAutoRenew",
                })}
              </DialogContentText>
              <Button
                onClick={() => this.cancelAutoRenew()}
                color="primary"
                autoFocus
                variant="contained"
                style={{ margin: "1em", fontWeight: "bold" }}
              >
                {intl.formatMessage({
                  id: "dashboard.yes",
                })}
              </Button>
              <Button
                autoFocus
                onClick={this.autoRenewNotCancelled.bind(this)}
                variant="contained"
                style={{ margin: "1em", color: "#660099", fontWeight: "bold" }}
              >
                {intl.formatMessage({
                  id: "dashboard.no",
                })}
              </Button>
            </DialogContent>
            <Footer dialogFooter={true} />
          </Dialog>

          <Card
            style={{
              backgroundColor: "white",
              boxShadow: "none",
              padding: "1em",
            }}
          >
            <CardContent style={{ backgroundColor: "white" }}>
              {(this.state.throttleRemoved || !this.state.throttled) &&
              !this.state.automaticRenewal ? (
                <div>
                  <Typography
                    style={{ textAlign: "center" }}
                    gutterBottom
                    variant="h6"
                  >
                    {intl.formatMessage({
                      id: "dashboard.notThrottled",
                    })}
                  </Typography>
                  <Typography gutterBottom variant="subtitle1">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.renewPrompt1",
                        }),
                      }}
                    ></span>
                    <b>
                      {this.state.planDefinition &&
                        (this.state.planDefinition.summary / 100)
                          .toFixed(2)
                          .replace(".", ",")}
                    </b>{" "}
                    {intl.formatMessage({
                      id: "dashboard.renewPrompt2",
                    })}
                  </Typography>
                  <CardActions className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="autoRenew"
                      onClick={this.confirmPurchase.bind(this)}
                      style={{ fontWeight: "bold" }}
                    >
                      {intl.formatMessage({
                        id: "dashboard.purchase",
                      })}
                    </Button>
                  </CardActions>
                  <Typography
                    style={{ marginBottom: "2em" }}
                    gutterBottom
                    variant="subtitle1"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.smsRepeatPurchase",
                        }),
                      }}
                    ></span>
                  </Typography>
                </div>
              ) : null}
              {(this.state.throttleRemoved || !this.state.throttled) &&
              this.state.automaticRenewal ? (
                <div>
                  <Typography gutterBottom variant="subtitle1">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.cancelActiveAutoRenew",
                        }),
                      }}
                    ></span>
                  </Typography>
                  <CardActions className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.confirmCancelAutoRenew.bind(this)}
                      style={{ fontWeight: "bold" }}
                    >
                      {intl.formatMessage({
                        id: "dashboard.cancel",
                      })}
                    </Button>
                  </CardActions>
                  <Typography
                    style={{ marginBottom: "2em" }}
                    gutterBottom
                    variant="subtitle1"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.cancelViaSms",
                        }),
                      }}
                    ></span>
                  </Typography>
                </div>
              ) : null}
              {this.state.throttled ? (
                <div>
                  <Typography
                    style={{ textAlign: "center" }}
                    gutterBottom
                    variant="h6"
                  >
                    {intl.formatMessage({
                      id: "dashboard.throttled",
                    })}
                  </Typography>
                  <Typography gutterBottom variant="subtitle1">
                    {"1 - "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.returnPrompt",
                        }),
                      }}
                    ></span>
                    <b>
                      {this.state.planDefinition &&
                        (this.state.planDefinition.summary / 100)
                          .toFixed(2)
                          .replace(".", ",")}
                    </b>{" "}
                    {intl.formatMessage({
                      id: "dashboard.price2",
                    })}
                  </Typography>
                  <CardActions className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="oneTimePurchase"
                      onClick={this.confirmPurchase.bind(this)}
                      style={{ fontWeight: "bold" }}
                    >
                      {intl.formatMessage({
                        id: "dashboard.purchase",
                      })}
                    </Button>
                  </CardActions>
                  <Typography
                    style={{ marginBottom: "2em" }}
                    gutterBottom
                    variant="subtitle1"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.smsOneTimePurchase",
                        }),
                      }}
                    ></span>
                  </Typography>
                  <Typography gutterBottom variant="subtitle1">
                    {"2 - "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.renewPrompt1",
                        }),
                      }}
                    ></span>
                    <b>
                      {this.state.planDefinition &&
                        (this.state.planDefinition.summary / 100)
                          .toFixed(2)
                          .replace(".", ",")}
                    </b>{" "}
                    {intl.formatMessage({
                      id: "dashboard.renewPrompt2",
                    })}
                  </Typography>
                  <CardActions className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="repeatPurchase"
                      onClick={this.confirmPurchase.bind(this)}
                      style={{ fontWeight: "bold" }}
                    >
                      {intl.formatMessage({
                        id: "dashboard.purchase",
                      })}
                    </Button>
                  </CardActions>
                  <Typography
                    style={{ marginBottom: "2em" }}
                    gutterBottom
                    variant="subtitle1"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "dashboard.smsRepeatPurchase",
                        }),
                      }}
                    ></span>
                  </Typography>
                </div>
              ) : null}
            </CardContent>
          </Card>
          <Footer showLink={true} />
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "white",
            height: "800px",
            overflow: "hidden",
          }}
        >
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(withStyles(styles)(Dashboard));
